import $ from 'jquery'

/*
 * Floating label plugin moves inline label to float above the field
 * when a user engages with the assosciated text input field
 */

const FloatingLabel = (($) => {
    // constants >>>
    const DATA_KEY    = 'md.floatinglabel'
    // const EVENT_KEY   = `.${DATA_KEY}`
    const NAME        = 'floatinglabel'
    const NO_CONFLICT = $.fn[NAME]

    const Selectors = {
        ARIA_CONTROLS: 'aria-controls',
        ROOT_SELECTOR: '.bo-text-field',
        INPUT_SELECTOR: '.bo-text-field__input',
        LABEL_SELECTOR: '.bo-floating-label',
        ICON_SELECTOR: '.bo-text-field__icon',
        OUTLINE_SELECTOR: '.bo-notched-outline',
        LINE_RIPPLE_SELECTOR: '.bo-line-ripple',
        HELPERTEXT_SELECTOR: '.bo-text-field-helper-text'
    }

    const cssClasses = {
        ROOT: 'bo-text-field',
        LABEL_FLOAT_ABOVE: 'bo-floating-label--float-above',
        LABEL_SHAKE: 'bo-floating-label--shake'
    }

    // <<< constants

    class FloatingLabel {
        constructor(element) {
            console.log('FloatingLabel::constructor')
            console.log(element)
            this._element = element
            this._label = element
            this._root  = $(element).closest(Selectors.ROOT_SELECTOR)[0]

            this.shakeAnimationEndHandler = () => this.handleShakeAnimationEnd()
            this.init()
        }

        init() {
            console.log('FloatingLabel::init')
            console.log(this._label)
            this.addShakeListener()
        }

        addShakeListener() {
            const self = this
            $(this._label).on('animationend', self.shakeAnimationEndHandler)
        }

        shake(shouldShake) {
            console.log('FloatingLabel::shake')
            console.log(shouldShake)
            const {
                LABEL_SHAKE
            } = cssClasses.LABEL_SHAKE
            if (shouldShake) {
                $(this._label).addClass(LABEL_SHAKE)
            } else {
                $(this._label).removeClass(LABEL_SHAKE)
            }
        }

        float(shouldFloat) {
            console.log('FloatingLabel::float')
            /**
            console.log(shouldFloat)
            const {
                LABEL_SHAKE
            } = cssClasses.LABEL_SHAKE
            const {
                LABEL_FLOAT_ABOVE
            } = cssClasses.LABEL_FLOAT_ABOVE
            **/
            if (shouldFloat) {
                console.log('float above')
                $(this._label).addClass('bo-floating-label--float-above')
            } else {
                console.log('no floating')
                $(this._label).removeClass('bo-floating-label--float-above')
                $(this._label).removeClass('bo-floating-label--shake')
            }
        }

        getWidth() {
            return this._root.offsetWidth
        }

        handleShakeAnimationEnd() {
            console.log('FloatingLabel::handleShakeAnimationEnd')
            /**
            const {
                LABEL_SHAKE
            } = cssClasses.LABEL_SHAKE
            **/
            $(this._label).removeClass('bo-floating-label--shake')
        }

        static _jQueryInterface(event) {
            return this.each(function () {
                const _event = event ? event : 'change'

                let data = $(this).data(DATA_KEY)

                if (!data) {
                    data = new FloatingLabel(this)

                    $(this).data(DATA_KEY, data)
                }

                if (typeof _event === 'string') {
                    if (typeof data[_event] === 'undefined') {
                        throw new Error(`No method named "${_event}"`)
                    }

                    data[_event]()
                }
            })
        }
    }
    /**
    $(document).on(`${Event.CHANGE} ${Event.FOCUSIN} ${Event.FOCUSOUT}`, Selector.DATA_TOGGLE, function (event) {
        FloatingLabel._jQueryInterface.call($(this), event.type)
    })
**/
    $.fn[NAME]             = FloatingLabel._jQueryInterface
    $.fn[NAME].Constructor = FloatingLabel
    $.fn[NAME].noConflict  = function () {
        $.fn[NAME] = NO_CONFLICT

        return FloatingLabel._jQueryInterface
    }

    return FloatingLabel
})($)

export default FloatingLabel
