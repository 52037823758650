import $ from 'jquery'
import FloatingLabel from './floating-label'
import LineRipple from './line-ripple'
/*
 * Floating label plugin moves inline label to float above the field
 * when a user engages with the assosciated text input field
 */

const TextField = (($) => {
    // constants >>>
    const DATA_KEY = 'md.textfield'
    const EVENT_KEY = `.${DATA_KEY}`
    const NAME = 'textfield'
    const NO_CONFLICT = $.fn[NAME]

    const Selectors = {
        ARIA_CONTROLS: 'aria-controls',
        ROOT_SELECTOR: '.bo-text-field',
        INPUT_SELECTOR: '.bo-text-field__input',
        LABEL_SELECTOR: '.bo-floating-label',
        ICON_SELECTOR: '.bo-text-field__icon',
        OUTLINE_SELECTOR: '.bo-notched-outline',
        LINE_RIPPLE_SELECTOR: '.bo-line-ripple',
        HELPERTEXT_SELECTOR: '.bo-text-field-helper-text'
    }

    const cssClasses = {
        ROOT: 'bo-text-field',
        UPGRADED: 'bo-text-field--upgraded',
        DISABLED: 'bo-text-field--disabled',
        DENSE: 'bo-text-field--dense',
        FOCUSED: 'bo-text-field--focused',
        INVALID: 'bo-text-field--invalid',
        BOX: 'bo-text-field--box',
        OUTLINED: 'bo-text-field--outlined',
        FULLWIDTH: 'bo-text-field--fullwidth',
        TEXTAREA: 'bo-text-field--textarea'
    }

    const Event = {
        CHANGE: `change${EVENT_KEY}`,
        FOCUS: `focus${EVENT_KEY}`,
        BLUR: `blur${EVENT_KEY}`
    }

    // <<< constants

    class TextField {
        constructor(element) {
            console.log('TextField::constructor')
            // console.log(element)
            this._element = element
            this._input = element
            this._root = $(element).closest(Selectors.ROOT_SELECTOR)[0]
            // console.log(this._root)
            this._lineRipple = null
            this._helperText = null
            this._label = null
            this._icon = null

            this.isFocused_ = false
            this.isValid_ = true
            this.receivedInput_ = false
            this.useCustomValidityChecking_ = false

            this.inputFocusHandler = () => this.handleFocus()
            this.inputBlurHandler = () => this.handleBlur()
            this.inputChangeHandler = () => this.handleChange()

            this.init()
        }

        init() {
            // console.log('TextField::init')
            $(this._root).addClass(cssClasses.UPGRADED)
            // console.log(this._root)
            const labelElement = $(this._root).find(Selectors.LABEL_SELECTOR)[0]
            // console.log(labelElement)
            if (labelElement) {
                this._label = new FloatingLabel(labelElement)
            }
            const lineRippleElement = $(this._root).find(Selectors.LINE_RIPPLE_SELECTOR)[0]
            console.log(lineRippleElement)
            if (lineRippleElement) {
                this._lineRipple = new LineRipple(lineRippleElement)
            }


            if (this.hasLabel() && this.getValue()) {
                this.addIsFilled()
            }
            if (this.isFocused()) {
                this.inputFocusHandler()
            }
            this.addFocusListener()
            this.addChangeListener()
        }

        change() {
            console.log('TextField::change')
            this.handleChange()
        }

        focusin() {
            console.log('TextField::focusin')
            // $(this._root).addClass(cssClasses.FOCUSED)
            // this.activateFocus()
            this.handleFocus()
        }

        focusout() {
            console.log('TextField::focusout')
            // $(this._root).removeClass(cssClasses.FOCUSED)
            // this.deactivateFocus()
            this.handleBlur()
        }

        addFocusListener() {
            const self = this
            // console.log('TextField::addFocusListener')
            $(this._input).on('focus', () => {
                self.handleFocus()
            }).on('blur', () => {
                self.handleBlur()
            })
        }

        addChangeListener() {
            const self = this
            // console.log('TextField::addChangeListener')
            $(this._input).on('change', () => {
                self.handleChange()
                /**
                if (this.isEmpty()) {
                    self.handleChange()
                    console.log('addChangeListener: is Empty')
                } else {
                    self.handleChange()
                    console.log('addChangeListener: is NOT Empty')
                } **/
            })
        }

        handleFocus() {
            console.log('TextField::handleFocus')
            if (!$(this._input).prop('disabled')) {
                $(this._root).addClass(cssClasses.FOCUSED)
                this._label.float(true)
                this.activateLineRipple()
            }
        }

        handleBlur() {
            console.log('TextField::handleFocus')
            $(this._root).removeClass(cssClasses.FOCUSED)
            if (this.isEmpty()) {
                this._label.float(false)
                this.deactivateLineRipple()
            } else {
                this._label.float(true)
                this.activateLineRipple()
            }
        }

        handleChange() {
            console.log('TextField::handleChange')
            if (this.isEmpty()) {
                this.removeIsFilled()
            } else {
                this.addIsFilled()
            }
            // check for validity
        }


        getValue() {
            return $(this._input).val()
        }

        setValue(value) {
            console.log('TextField::setValue')
            this._input.value = value
            const isValid = this.isValid()
            this.styleValidity(isValid)
            if (this.hasLabel()) {
                this.shakeLabel(this.shouldShake)
                this.floatLabel(this.shouldFloat)
            }
        }

        isValid() {
            return this.useCustomValidityChecking_ ? this.isValid_ : this.isInputValid()
        }

        isEmpty() {
            return $(this._input).val() === null || $(this._input).val() === undefined || $(this._input).val() === ''
        }

        isFocused() {
            if (document.activeElement === this._root.querySelector(Selectors.INPUT_SELECTOR)) {
                this.isFocused_ = true
                console.log('TextField::isFocused - yes!')
            }
        }

        hasLabel() {
            return Boolean(this._label)
        }

        setValid(isValid) {
            console.log('TextField::setValid')
            this.useCustomValidityChecking_ = true
            this.isValid_ = isValid
            isValid = this.isValid()
            this.styleValidity(isValid)
            if (this.hasLabel()) {
                this.shakeLabel(this.shouldShake)
            }
        }

        isDisabled() {
            return this._input.disabled
        }

        setDisabled(disabled) {
            this._input.disabled = disabled
            this.styleDisabled(disabled)
        }

        styleValidity(isValid) {
            console.log('TextField::styleValidity')
            const {
                INVALID
            } = cssClasses.INVALID
            if (isValid) {
                $(this._root).removeClass(INVALID)
            } else {
                $(this._root).addClass(INVALID)
            }
            if (this._helperText) {
                this._helperText.setValidity(isValid)
            }
        }

        styleFocused(isFocused) {
            console.log('TextField::styleFocused')
            const {
                FOCUSED
            } = cssClasses.FOCUSED
            if (isFocused) {
                $(this._root).addClass(FOCUSED)
            } else {
                $(this._root).removeClass(FOCUSED)
            }
        }

        styleDisabled(isDisabled) {
            console.log('TextField::styleDisabled')
            const {
                DISABLED
            } = cssClasses.DISABLED
            const {
                INVALID
            } = cssClasses.INVALID
            if (isDisabled) {
                $(this._root).addClass(DISABLED)
                $(this._root).removeClass(INVALID)
            } else {
                $(this._root).removeClass(DISABLED)
            }
            if (this._icon) {
                this._icon.setDisabled(isDisabled)
            }
        }
        activateLineRipple() {
            console.log('TextField::activateLineRipple')
            if (this._lineRipple) {
                this._lineRipple.activate()
            }
        }
        deactivateLineRipple() {
            console.log('TextField::deactivateLineRipple')
            if (this._lineRipple) {
                this._lineRipple.deactivate()
            }
        }

        getNativeInput() {
            return this._input || {
                value: '',
                disabled: false,
                validity: {
                    badInput: false,
                    valid: true
                }
            }
        }

        // Getters and Setters
        get disabled() {
            return this.isDisabled()
        }

        set disabled(disabled) {
            this.setDisabled(disabled)
        }

        get valid() {
            return this.isValid()
        }

        set valid(valid) {
            this.setValid(valid)
        }

        get required() {
            return this._input.required
        }

        set required(required) {
            this._input.required = required
        }


        static _jQueryInterface(event) {
            return this.each(function () {
                const _event = event ? event : 'change'

                let data = $(this).data(DATA_KEY)
                if (!data) {
                    data = new TextField(this)
                    $(this).data(DATA_KEY, data)
                }
                if (typeof _event === 'string') {
                    if (typeof data[_event] === 'undefined') {
                        throw new Error(`No method named "${_event}"`)
                    }
                    data[_event]()
                }
            })
        }
    }

    $(document).on(`${Event.CHANGE} ${Event.FOCUS} ${Event.BLUR}`, Selectors.INPUT_SELECTOR, function (event) {
        console.log(`trigger type: ${event.type}`)
        TextField._jQueryInterface.call($(this), event.type)
    })

    $.fn[NAME] = TextField._jQueryInterface
    $.fn[NAME].Constructor = TextField
    $.fn[NAME].noConflict = function () {
        $.fn[NAME] = NO_CONFLICT
        return TextField._jQueryInterface
    }

    return TextField
})($)

export default TextField
