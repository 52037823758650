import $ from 'jquery'

/*
 * Floating label plugin moves inline label to float above the field
 * when a user engages with the assosciated text input field
 */

const LineRipple = (($) => {
    // constants >>>
    const DATA_KEY = 'md.lineripple'
    // const EVENT_KEY = `.${DATA_KEY}`
    const NAME = 'lineripple'
    const NO_CONFLICT = $.fn[NAME]

    const Selectors = {
        ARIA_CONTROLS: 'aria-controls',
        ROOT_SELECTOR: '.bo-text-field',
        INPUT_SELECTOR: '.bo-text-field__input',
        LABEL_SELECTOR: '.bo-floating-label',
        ICON_SELECTOR: '.bo-text-field__icon',
        OUTLINE_SELECTOR: '.bo-notched-outline',
        LINE_RIPPLE_SELECTOR: '.bo-line-ripple',
        HELPERTEXT_SELECTOR: '.bo-text-field-helper-text'
    }

    const ClassName = {
        LINE_RIPPLE_ACTIVE: 'bo-line-ripple--active',
        LINE_RIPPLE_DEACTIVATING: 'bo-line-ripple--deactivating'
    }

    // <<< constants

    class LineRipple {
        constructor(element) {
            this._element = element
            this._lineRipple = element
            this._root = $(element).closest(Selectors.ROOT_SELECTOR)[0]
            this.init()
        }

        init() {
            $(this._element).on('transitionend', this.handleTransitionEnd)
        }

        destroy() {
            $(this._element).off('transitionend')
        }

        activate() {
            $(this._element).removeClass(ClassName.LINE_RIPPLE_DEACTIVATING)
            $(this._element).addClass(ClassName.LINE_RIPPLE_ACTIVE)
        }

        deactivate() {
            $(this._element).addClass(ClassName.LINE_RIPPLE_DEACTIVATING)
        }

        setRippleCenter(xCoordinate) {
            $(this._element).setStyle('transform-origin', `${xCoordinate}px center`)
        }

        setStyle(propertyName, value) {
            $(this._element).css(propertyName, value)
        }

        handleTransitionEnd(event) {
            const isDeactivating = $(this._element).hasClass(ClassName.LINE_RIPPLE_DEACTIVATING)

            if (event.propertyName === 'opacity') {
                if (isDeactivating) {
                    $(this._element).removeClass(ClassName.LINE_RIPPLE_ACTIVE)
                    $(this._element).removeClass(ClassName.LINE_RIPPLE_DEACTIVATING)
                }
            }
        }

        static _jQueryInterface(event) {
            return this.each(function () {
                const _event = event ? event : 'change'

                let data = $(this).data(DATA_KEY)

                if (!data) {
                    data = new LineRipple(this)

                    $(this).data(DATA_KEY, data)
                }

                if (typeof _event === 'string') {
                    if (typeof data[_event] === 'undefined') {
                        throw new Error(`No method named "${_event}"`)
                    }

                    data[_event]()
                }
            })
        }
    }

    /**
    $(document).on(`${Event.CHANGE} ${Event.FOCUSIN} ${Event.FOCUSOUT}`, Selector.DATA_TOGGLE, function(event) {
        LineRipple._jQueryInterface.call($(this), event.type)
    })
    **/
    $.fn[NAME] = LineRipple._jQueryInterface
    $.fn[NAME].Constructor = LineRipple
    $.fn[NAME].noConflict = function () {
        $.fn[NAME] = NO_CONFLICT

        return LineRipple._jQueryInterface
    }

    return LineRipple
})($)

export default LineRipple
